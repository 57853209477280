import { Injectable } from '@angular/core';
import {
  fromEvent,
  merge,
  Observable,
  of
} from "rxjs";
import {map} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class NetworkCheckService {
  public appIsOnline$ !: Observable<boolean>;

  constructor() {



  }

  public initConnectivityMonitoring() {

    if (!window || !navigator || !('onLine' in navigator)) {
      return
    }

    this.appIsOnline$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    ).pipe(map(() => navigator.onLine));

  }
}

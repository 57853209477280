import {AfterViewInit, Component, inject, NgZone, OnInit} from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import {ApiService} from "src/app/core/services/api.service";
import { App, URLOpenListenerEvent } from '@capacitor/app';
import {Router} from '@angular/router';
import {environment} from "src/environments/environment";
import * as CookieConsent from 'vanilla-cookieconsent';
import {map} from "rxjs/operators";
import { Capacitor } from '@capacitor/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {

  private hasContentTypeInStorage = !!localStorage.getItem('contentTypes');

  constructor(
    private apiService: ApiService,
    private zone: NgZone,
    private router: Router,
    private analytics: AngularFireAnalytics = inject(AngularFireAnalytics)
  ) {
    if(Capacitor.isNativePlatform()) {
      SplashScreen.hide();
    }

    this.handleUrlOpen();
  }

  async ngOnInit() {

    this.handleCommonTypesConfig();

    // const logs = this.getAllLogsByType('generic').subscribe();
  }

  ngAfterViewInit() {
    if(!Capacitor.isNativePlatform()) {
      const defaultCCConfig = environment.cookieConsentV3Config;
      const ccConfig: CookieConsent.CookieConsentConfig = {
        ...environment.cookieConsentV3Config,
        onChange: ({cookie, changedCategories, changedServices}) => {
          if( ! changedCategories.includes('analytics') || ! this.analytics) {
            return;
          }

          if(CookieConsent.acceptedCategory('analytics')) {
            this.analytics.setAnalyticsCollectionEnabled(true);
            const userId = localStorage.getItem('userId');

            if(userId) {
              this.analytics.setUserId(userId);
            }
          } else {
            this.analytics.setAnalyticsCollectionEnabled(false);
            CookieConsent.eraseCookies(['_gid', /^_ga/], '/', location.hostname);
          }
        }
      };

      CookieConsent.run(Object.assign({}, defaultCCConfig, ccConfig));
    }
  }

  private handleUrlOpen() {

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = environment.application.domain;
        const pathArray = event.url.split(domain);
        // The pathArray is now like ['https://domain.com', '/details/42']

        // Get the last element with pop()
        const appPath = pathArray.pop();

        if (appPath) {
          this.router.navigateByUrl(appPath);
        }
      });
    });
  }

  private genericLog(data: any) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'generic',
          "data": data
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }

  private getAllLogsByType(type: string) {
    return this.apiService
      .get<any>('/activity_log?=' + type)
      .pipe(map(data => {
        return data;
      }));
  }

  private handleCommonTypesConfig() {
    this.apiService.get('/common/content_types').subscribe((res: any) => {
      const isPaymentDisabled = res.data.find((res2: any) => res2.id === 'payment_disabled');

      localStorage.setItem('contentTypes', JSON.stringify(res.data));


      if (isPaymentDisabled) {
        localStorage.setItem('isPaymentDisabled', JSON.stringify(isPaymentDisabled));
      } else {
        localStorage.removeItem('isPaymentDisabled');
      }
    });
  }
}

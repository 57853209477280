import {
  Injectable,
  isDevMode,
  NgModule,
} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {
  PreloadAllModules,
  Router,
  RouterModule,
  RouterStateSnapshot,
  Routes,
  TitleStrategy,
} from "@angular/router";
import {environment} from "src/environments/environment";

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/pages/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    title: 'Hitelesítés',
    loadChildren: () => import('./core/modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'pages',
    title: 'Bejelentkezéshez kötött',
    loadChildren: () => import('./pages/pages.module').then( m => m.PagesModule),
  },
  { path: '**', redirectTo: '/pages/dashboard' },
];

@Injectable({ providedIn: 'root' })
export class TemplatePageTitleStrategy extends TitleStrategy {

  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    const sitename : string = environment.application.name;

    if(title !== undefined) {
      this.title.setTitle(`${title} - ${sitename}`);
    }
  }
}

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      enableTracing: isDevMode() && environment.debugRouting,
      preloadingStrategy: PreloadAllModules,
      bindToComponentInputs: true,
      initialNavigation: "enabledBlocking",
      paramsInheritanceStrategy: "always",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {

  // Diagnostic only: inspect router configuration
  constructor(private readonly router: Router) {

    if(isDevMode() && environment.debugRouting) {
      // Use a custom replacer to display function names in the route configs
      const replacer = (key : any, value : any) => (typeof value === 'function') ? value.name : value;

      console.log('Routes: ', JSON.stringify(router.config, replacer, 2));
    }

  }
}

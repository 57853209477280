import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {ToastService} from "src/app/core/services/toast-service.service";
import {NetworkCheckService} from "src/app/core/services/network-check.service";


@Injectable()
export class NoNetInterceptorService implements HttpInterceptor {
  constructor(private toastService: ToastService, private networkCheckService: NetworkCheckService) {
    this.networkCheckService.initConnectivityMonitoring();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let isOnline = false;
    this.networkCheckService.appIsOnline$.subscribe(isConnected=>{
      isOnline = isConnected;
    });
    // check to see if there's internet and check method type
    if (!isOnline && (request.method ==='PUT' || request.method ==='POST')) {
      this.toastService.openToast('Internet is not available');
      return new Observable(observer => {
        observer.error(new HttpErrorResponse({ status: 0, error: 'Internet is not available' }));
      });
    } else {
      // else return the normal request
      return next.handle(request);
    }
  }
}

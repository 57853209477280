import {
  enableProdMode,
  isDevMode,
} from "@angular/core";
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(ref => {

    /*if (isDevMode()) {
      // Ensure Angular destroys itself on hot reloads.
      // @ts-ignore
      if(window['ngRef']) {
        // @ts-ignore
        window['ngRef'].destroy();
      }
      // @ts-ignore
      window['ngRef'] = ref;
    }*/

  })
  // Otherwise, log the boot error
  .catch(err => console.log(err));

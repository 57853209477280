import {EventEmitter, Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notificationRecivedEmitter = new EventEmitter<any>();

  constructor(private apiService: ApiService) { }

  getNotifications() {
    return this.apiService
      .get<any>('/reminders')
      .pipe(map(data => {
        return data;
      }));
  }
}

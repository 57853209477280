import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  isDevMode,
} from "@angular/core";
import { AngularFireMessagingModule} from "@angular/fire/compat/messaging";
import { BrowserModule } from '@angular/platform-browser';
import {BACKEND_URL} from "src/app/core/core.injectors";
import { AppComponent } from './app.component';
import {
  AppRoutingModule,
  TemplatePageTitleStrategy,
} from "./app-routing.module";
import {CoreModule} from "src/app/core/core.module";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {environment} from "src/environments/environment";
import { AngularFireModule } from "@angular/fire/compat";
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
  COLLECTION_ENABLED,
  CONFIG,
  DEBUG_MODE,
  APP_NAME,
  APP_VERSION,
} from '@angular/fire/compat/analytics';
import {
  AngularFirePerformanceModule,
  DATA_COLLECTION_ENABLED,
  INSTRUMENTATION_ENABLED,
  PerformanceMonitoringService,
} from "@angular/fire/compat/performance";
import {FcmService} from "src/app/core/services/firebase-cloud-messaging.service";
import { FirebaseAppSettings, FirebaseOptions } from '@angular/fire/app';
import {IonApp,
  IonContent,
  IonRouterOutlet,
  IonicRouteStrategy, provideIonicAngular } from "@ionic/angular/standalone";
import {RouteReuseStrategy, TitleStrategy } from "@angular/router";

const FIREBASE_ANALYTICS_ENABLED_VALUE: boolean = environment.firebaseConfig.enabled;
//const FIREBASE_ANALYTICS_ENABLED_VALUE: boolean = false;
const FIREBASE_CONFIG_VALUE: FirebaseOptions = environment.firebaseConfig.web;
const FIREBASE_APP_SETTINGS_VALUE: FirebaseAppSettings = { automaticDataCollectionEnabled: FIREBASE_ANALYTICS_ENABLED_VALUE };
const FIREBASE_ANALYTICS_CONFIG_VALUE: object = {
  send_page_view: FIREBASE_ANALYTICS_ENABLED_VALUE,
  allow_google_signals: FIREBASE_ANALYTICS_ENABLED_VALUE,
  allow_ad_personalization_signals: FIREBASE_ANALYTICS_ENABLED_VALUE,
  anonymize_ip: true,
};

/*

const FIREBASE_ANALYTICS_ENABLED = new InjectionToken<boolean>('AngularFireModule::FIREBASE_ANALYTICS_ENABLED');
const FIREBASE_CONFIG = new InjectionToken<FirebaseOptions>('AngularFireModule::FIREBASE_CONFIG');
const FIREBASE_APP_SETTINGS = new InjectionToken<FirebaseAppSettings>('AngularFireModule::FirebaseAppSettings');
const FIREBASE_ANALYTICS_CONFIG = new InjectionToken<object>('AngularFireModule::FIREBASE_ANALYTICS_CONFIG');

 */

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(FIREBASE_CONFIG_VALUE, FIREBASE_APP_SETTINGS_VALUE), // Initialize Firebase
    AngularFireAnalyticsModule,
    AngularFireMessagingModule,
    AngularFirePerformanceModule,
    CoreModule,
    IonApp,
    IonContent,
    IonRouterOutlet,
    AppRoutingModule,
  ],
  providers: [
    { provide: BACKEND_URL, useValue: environment.api_url },
    provideIonicAngular({
      rippleEffect: false,
      mode: 'md',
      // animated: true,
    }),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: TitleStrategy, useClass: TemplatePageTitleStrategy },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorHandlingInterceptor,
    //   multi: true,
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: CommonHttpInterceptor,
    //   multi: true,
    // },
    FcmService,
    ScreenTrackingService,
    UserTrackingService,
    PerformanceMonitoringService,
    { provide: INSTRUMENTATION_ENABLED, useValue: FIREBASE_ANALYTICS_ENABLED_VALUE },
    { provide: DATA_COLLECTION_ENABLED, useValue: FIREBASE_ANALYTICS_ENABLED_VALUE },
    { provide: CONFIG, useValue: FIREBASE_ANALYTICS_CONFIG_VALUE },
    { provide: COLLECTION_ENABLED, useValue: FIREBASE_ANALYTICS_ENABLED_VALUE },
    { provide: DEBUG_MODE, useFactory: (function () { return isDevMode() && environment.firebaseConfig.debug }) },
    { provide: APP_NAME, useValue: environment.application.name },
    { provide: APP_VERSION, useValue: environment.application.version || '2.0.0' },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // 402 - előfizetés szükséges
        // 403 - akkor érkezik vissza, ha egyszerre 3 kihíváson már dolgozik ( pl.: több tabba van nyitva )
        if (error.status === 401) {
          if (!this.router.url.includes('auth')) {
            this.router.navigate(['/auth/login']);
          }
        }

        return throwError(() => error);
      })
    );
  }
}

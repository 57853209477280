import { Injectable } from '@angular/core';
import { ToastController } from "@ionic/angular/standalone";

@Injectable()
export class ToastService {

  constructor(private toastCtrl: ToastController) { }

  async openToast(message: string, position: 'bottom' | 'middle' | 'top' = 'bottom'): Promise<void> {
    const toast = await this.toastCtrl.create({
      message,
      duration: 7000,
      keyboardClose: true,
      position: position,
    });

    return await toast.present();
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { UserService } from './user.service';
import {LocalstorageService} from "./localstorage.service";

@Injectable()
export class AuthGuard implements CanActivate {
  isLoggedIn : boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private localStorage: LocalstorageService
  ) {}

    canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): boolean {
      const token = this.localStorage.getItem('jwtToken');
      // return true;

      if(!!token){
        console.log(this.userService.getCurrentUser());
      }

     this.userService.isAuthenticated.subscribe(isAuth => this.isLoggedIn = isAuth);

      if(!this.isLoggedIn && !token){
        this.router.navigate(['/login']);
        return false;
      } else{
        return true;
      }
    }
  }

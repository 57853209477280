import {
  NgForOf,
  NgIf,
} from "@angular/common";
import {ChangeDetectorRef, Component, Input } from '@angular/core';
import {Errors} from "src/app/core/models";
import { isObject } from "lodash";

@Component({
  selector: "app-list-errors",
  templateUrl: "./list-errors.component.html",
  standalone: true,
  imports: [NgIf, NgForOf],
})
export class ListErrorsComponent {

  formattedErrors: Array<string> = [];

  constructor(
    private cdr: ChangeDetectorRef,
  ) {}

  @Input()
  set errorStrings(errorList: any) {
    this.formattedErrors = errorList.map((err: any) => {
      if (isObject(err)) {
        const errorKeys = Object.keys(err);

        // @ts-ignore
        return err[errorKeys[0]];
      }
      return err;
    });

    this.cdr.markForCheck();
  }
  @Input()
  set errors(errorList: Errors) {
    this.formattedErrors = Object.keys(errorList.errors || {}).map(
      key => `${errorList.errors[key]}`,
    );

    this.cdr.markForCheck();
  }

  get errorList() {
    return this.formattedErrors;
  }
}
